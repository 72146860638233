<template>
    <div id="app" :class="{'auth__background': authBg && canLogin,'app__background': !authBg && canLogin, 'nologin__background': !canLogin }">
        <div v-if="canLogin" class="d-flex flex-column justify-content-between" style="min-height: 100vh">
            <div>
                <navigation-component></navigation-component>
                <transition name="move-down" mode="out-in">
                    <router-view/>
                </transition>
            </div>
            <footer-component></footer-component>
        </div>
        <div v-else-if="canLogin===false" class="d-flex flex-column justify-content-start align-items-center" style="min-height: 100vh">
            <div class="container">
                <div class="row">
                    <div class="offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4">
                        <div class="text-center position-relative">
                            <div class="logo d-inline-block my-5">
                                <router-link :to="{name: 'home'}">
                                    <img src="@/img/MC_Elavon_klub_korzysci_logotyp_czarne_v1_TF.png" alt="" class="mw-100">
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <div class="position-relative">
                            <div class="plug__text">
                                <p class="fw-bold color-text plug__text--1">
                                    Strona niedostępna.
                                </p>
                                <p class="plug__text--2">
                                    <b class="color-main">Klub Korzyści Elavon&nbsp;2</b> został zakończony i&nbsp;nie ma już możliwości wymiany punktów.
                                </p>
                                <p class="plug__text--3">
                                    Dziękujemy za Wasze zaangażowanie w&nbsp;tej edycji. Do usłyszenia!
                                </p>
                            </div>
                            <img class="mw-100" src="~@/assets/plug.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <loader-component />
        </div>
        <popup-component></popup-component>
        <cookie-component v-if="false"></cookie-component>
        <cookie-banner-component v-if="$route.name !== 'cookies'"></cookie-banner-component>
        <loader-component v-if="isLoading" />
    </div>
</template>

<script>
import NavigationComponent from "@/components/modules/NavigationComponent";
import CookieComponent from "@/components/modules/CookieComponent";
import FooterComponent from "@/components/modules/FooterComponent";
import PopupComponent from "@/components/modules/PopupComponent";
import LoaderComponent from "@/components/modules/LoaderComponent";
import CookieBannerComponent from "@/components/modules/CookieBannerComponent";
export default {
    name: 'App',
    components: {
        CookieBannerComponent,
        LoaderComponent,
        NavigationComponent,
        CookieComponent,
        FooterComponent,
        PopupComponent
    },
    data() {
        return {
            authBg: true,
            authPathNames: ['login', 'retrieve', 'register', 'resetPassword'],
            canLogin: null
        }
    },
    mounted() {
        window.api.get('system')
            .then(response => {
                this.canLogin = response.data.data.on;

            })
            .catch(() => {
                this.canLogin = false;
            })
    },
    computed: {
        // canLogin(){
        //     let canLogin = this.$store.getters.canLogin;
        //     // let date = new Date();
        //     // date = date.toISOString().substring(0,19);
        //     // if(date >= '2023-05-31T22:00:00'){
        //     //     canLogin = false;
        //     // }
        //     return canLogin;
        // },
        isLoading: function (){
            return this.$store.getters.isLoading;
        }
    },
    watch: {
        $route(to) {
            this.authBg = this.authPathNames.includes(to.name);
        }
    },
}
</script>

<style lang="scss">
    @import "src/styles/app";
    .auth__background {
        background: linear-gradient(258deg, rgba(0,100,124,1) 0%, rgba(0,78,97,1) 100%);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
    }
    .app__background {
        background: linear-gradient(180deg, rgba(250,252,254,1) 0%, rgba(225,237,250,1) 100%);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
    }
    .nologin__background{
        background: #f6fafd url("~@/assets/bg_nologin.jpg") no-repeat center center;
        background-size: cover;
        min-height: 100vh;
    }
    .plug__text{
        position: absolute;
        text-align: left;
        padding: 2rem;
        width: 60%;
        left:170px;
        top:0.5rem;
        max-width:590px;
        @media (max-width: 1399px) {
            left:80px;
        }
        @media (max-width: 1199px) {
            left:16px;
        }
        @media (max-width: 767px) {
            padding: 1.5rem;
        }
        @media (max-width: 575px) {
            padding: 1rem;
        }
        @media (max-width: 499px) {
            padding: 0.5rem 1rem 0.5rem 0.5rem;
            left:12px;
        }
        @media (max-width: 399px) {
            padding: 0.25rem 1rem 0.25rem 0.25rem;
        }
        &--1{
            @media (max-width: 767px) {
                font-size: 14px;
                margin-bottom: 0.5rem;
            }
            @media (max-width: 575px) {
                font-size: 13px;
            }
            @media (max-width: 499px) {
                margin-bottom: 0.25rem;
                font-size: 11px;
            }
        }
        &--2{
            font-size: 32px;
            @media (max-width: 991px) {
                font-size: 24px;
            }
            @media (max-width: 767px) {
                font-size: 18px;
                margin-bottom: 0.5rem;
            }
            @media (max-width: 575px) {
                font-size: 16px;
            }
            @media (max-width: 499px) {
                margin-bottom: 0.25rem;
                font-size: 13px;
            }
            @media (max-width: 399px) {
                font-size: 12px;
            }
        }
        &--3{
            font-size: 24px;
            @media (max-width: 991px) {
                font-size: 18px;
            }
            @media (max-width: 767px) {
                font-size: 15px;
                margin-bottom: 0.5rem;
            }
            @media (max-width: 575px) {
                font-size: 14px;
            }
            @media (max-width: 499px) {
                margin-bottom: 0.25rem;
                font-size: 11px;
            }
        }
    }
</style>
